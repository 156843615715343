/************************************************
 * CUSTOMER TRANSACTION (list item)
 ***********************************************/
.customer-dashboard .customer-transaction {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #efefef;
    padding: 6px 6px;
    box-sizing: border-box;
}

.customer-transaction .cell {
    margin: 0 6px;
}

.customer-dashboard .customer-warning-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: var(--default-card-spacing);
}
.customer-dashboard .customer-warning-text svg {
    fill: var(--color-complementary);
    width: 20px;
    height: auto;
    margin: 0 3px;
}