@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("./fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}


body {
  --default-spacing: 24px;
  --default-card-spacing: 12px;
  --default-card-border-radius: 5px;
  --default-button-padding: 6px 24px;
  --default-front-page-spacing: 14vw;
  --font-enormous: clamp(14px, 6vw, 60px);
  --font-xxxlarge: clamp(10px, 3.5vw, 36px);
  --font-xxlarge: clamp(10px, 2.5vw, 26px);
  --font-xlarge: clamp(18px, 2vw, 20px);
  --font-large: 16px;
  --font-normal: 14px;
  --font-small: 11px;
  --font-xsmall: 9px;
  --color-complementary: #E69729;
  --color-accent: #2ecafe;
  --color-accent-mkt: #1DC275;
  --color-primary: #2e62fe;
  --color-primary-light: #91BAF3;
  --color-background: #F8FBFF;
  --color-text-dark: rgba(0, 0, 0, 0.87);

  --header-height: 2vh;
  --header-logo-height: 2.5vh;
  --footer-height: 4vw;

  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.87);
}

.dark-background {
  color: white;
}

.light-background {
  color: rgba(0, 0, 0, 0.87);
}

/***********+ REACT-POPUP *************/
.popup-overlay {
  background: rgba(0, 0, 0, 0.4);
}

/**** TEXT FONTS ************/
.font-righteous {
  font-family: 'Poppins';
}

.font-enormous {
  font-size: var(--font-enormous);
}

.font-xxxlarge {
  font-size: var(--font-xxxlarge);
}

.font-xxlarge {
  font-size: var(--font-xxlarge);
}

.font-xlarge {
  font-size: var(--font-xlarge);
}

.font-large {
  font-size: var(--font-large);
}

.font-normal {
  font-size: var(--font-normal);
}

.font-small {
  font-size: var(--font-small);
}

.font-xsmall {
  font-size: var(--font-xsmall);
}

.bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

/********** INPUTS ***************/
input[type='text'] {
  font-size: var(--font-normal);
  font-family: '';
}

/**** THEME COLORS ************/
.primary {
  color: #2e62fe;
}

.accent {
  color: #29D6E6;
}

.complementary {
  color: #E69729;
}

.primary-light {
  color: #92CCFF;
}

.greyed {
  color: #EDEDED;
}

.border-primary {
  border-color: #2e62fe;
}

.border-accent {
  border-color: #2ecafe;
}

.border-complementary {
  border-color: #E69729;
}

.outline-primary {
  outline-color: #2e62fe;
}

.fill-accent {
  fill: #2ecafe;
  background-color: #2ecafe;
}

.fill-primary {
  fill: #2e62fe;
  background-color: #2e62fe;
}

.fill-complementary {
  fill: #E69729;
  background-color: #E69729;
}

.fill-complementary-light {
  fill: #ffecd2;
  background-color: #ffecd2;
}

svg.primary {
  fill: var(--color-primary);
}

svg.complementary {
  fill: var(--color-complementary);
}

svg.accent {
  fill: var(--color-accent);
}


/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
  body {
    --default-spacing: 12px;
    --default-card-spacing: 6px;
    --default-card-border-radius: 5px;
    --default-button-padding: 12px 32px;
    --font-enormous: clamp(14px, 6vw, 60px);
    --font-xxxlarge: clamp(10px, 3.5vw, 36px);
    --font-xxlarge: clamp(10px, 2.5vw, 26px);
    --font-xlarge: clamp(14px, 2vw, 20px);
    --font-large: clamp(12px, 1.7vw, 16px);
    --font-normal: clamp(10px, 1vw, 14px);
    --font-small: clamp(8px, 1vw, 12px);
    --font-xsmall: clamp(6px, 0.8vw, 10px);

    --header-height: 8vh;
    --header-logo-height: 2.5vh;
    --footer-height: 4vw;
  
  }
  input[type='text'] {
    font-size: 3vw;
    font-family: '';
  }

}