
.np-text-input {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 6px 0;
    font-size: var(--font-normal);
}
.np-text-input.short {
    width: 50%;
}
.np-text-input.shortest {
    width: 30%;
}

.np-text-input .np-input-label {
    text-align: left;
    padding-left: 13px;
}

.np-text-input input[type='text'], .np-text-input input[type='password'] {
    border-width: 1px;
    border-style: solid;
    background-color: white;
    border-radius: 5px;
    width: 100%;
    margin: 6px 0;
    height: 38px;
    padding-left: 12px;
    box-sizing: border-box;
    color: currentColor;
    font-family: Poppins;
}

.np-text-input .currency + input[type="text"] {
    padding-left: 36px;
}

.np-text-input .border-ok {
    border-color: #e1e1e1;
}

.np-text-input .npti-content {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}
.np-text-input .npti-content .npti-button-container {
    margin-left: 12px;
}

.np-text-input .npti-content .text-input-container {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}
.np-text-input .text-input-container .feedback-icon {
    position: absolute;
    top: 14px;
    width: 20px;
    height: 20px;
    fill: currentColor;
}
.np-text-input .text-input-container .feedback-icon svg {
    height: 20px;
    width: 20px;
}

.np-text-input .text-input-container .info-icon {
    margin-left: 12px;
    cursor: pointer;
}
.np-text-input .text-input-container .info-icon svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
}

.np-text-input .info-popup-content {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}
.np-text-input .info-popup-content .info-popup-img {
    display: flex;
    flex-grow: 1;
    max-width: 128px;
}
.np-text-input .info-popup-content .info-popup-text {
    display: flex;
    flex-grow: 3;
    text-align: left;
    margin-left: 24px;
    align-items: center;
}

.np-text-input .currency {
    margin-right: 12px;
    margin-left: 12px;
    position: absolute;
    z-index: 2;
    font-size: var(--font-xlarge);
}

/**********************************************************
 * SUGGESTIONS BOX
 *********************************************************/
.np-text-input .suggestions-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 69px;
    padding: 0;
    background: white;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    z-index: 3;
}
.np-text-input .suggestions-box .suggestion-item {
    padding: 12px 14px;
    cursor: pointer;
}
.np-text-input .suggestions-box .suggestion-item.first {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.np-text-input .suggestions-box .suggestion-item.last {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.np-text-input .suggestions-box .suggestion-item:hover, .suggestions-box .suggestion-item.highlighted {
    background-color: var(--color-accent);
    color: white;
}