.user-activated-screen {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0 8vw;
}

.user-activated-screen .registered-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 8vw;
    flex: 1;
    max-width: 40vw;
    margin-top: 20vh;
}

.user-activated-screen .registered-component .title {
    font-size: var(--font-section-title);
    color: var(--color-primary);
}

.user-activated-screen .registered-component .text.sidebar {
    border-left: 4px solid var(--color-accent-mkt);
    padding-left: 2vw;
}

.user-activated-screen .registered-component .text {
    font-size: var(--font-main-text);
}

.user-activated-screen .registered-component .vspacing {
    margin: 12px 0;
}

.user-activated-screen .registered-component .vspacing.x2 {
    margin: 24px 0;
}

.user-activated-screen .registered-component img {
    height: 4vw;
}

.user-activated-screen .activation-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20vh;
    width: 30vw;
    max-width: 30vw;
}



/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .user-activated-screen {
        flex-direction: column;
        align-items: center;
    }
    
    .user-activated-screen .registered-component {
        flex-direction: column;
        margin-right: 0;
        max-width: none;
        margin-top: 10vh;
    }
    
    .user-activated-screen .registered-component img {
        height: 6vh;
    }
    .user-activated-screen .activation-image {
        margin: 5vh 0;
        max-width: none;
        width: 40vw;
    }
    

}