
.agency-dashboard .list-container {
    margin-top: var(--default-spacing);
}
.agency-dashboard .new-tx-container {
    padding-left: var(--default-spacing);
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .agency-dashboard .new-tx-container {
        margin-top: var(--default-spacing);
    }
}
