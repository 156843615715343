
.np-checkbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: rgba(0,0,0,0.7);
    cursor: pointer;
}
.np-checkbox .row {
    display: flex;
    align-items: center;
}
.np-checkbox .row:hover {
    color: var(--color-accent);
}
.np-checkbox .box {
    width: 18px;
    min-width: 18px;
    height: 18px;
    border: 2px solid currentColor;
    border-radius: 5px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.np-checkbox .box svg {
    width: 12px;
    height: auto;
    fill: currentColor;
}

.np-checkbox.edit-true {
    color: var(--color-primary);
    cursor: pointer;
}
.np-checkbox.edit-true:hover {
    opacity: 0.5;
}
.np-checkbox .links {
    margin-left: 32px;
    margin-top: 6px;
    font-size: var(--font-small);
    display: flex;
    flex-direction: row;
}
.np-checkbox .links .link {
    margin-right: 12px;
}
.np-checkbox .links .link a:hover {
    color: var(--color-accent);
}