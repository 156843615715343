.login-screen {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.login-container {
    margin-top: 20vh;
    border: 1px solid #e6e6f0;
    padding: 72px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
}

.login-container .logo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 48px;
}

.login-container .logo-container .pleggit-logo svg {
    max-height: 3vw;
    width: auto
}

.login-container .logo-container .pleggit-title svg {
    fill: var(--color-primary);
    width: auto;
    max-height: 3vw;
}

.login-container .input-container {
    display: flex;
    flex-direction: column;
    max-width: 30vw;
}

.login-container .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 24px;
}

.login-container .error-container {
    text-align: center;
    color: var(--color-complementary);
    font-size: var(--font-small);
}


/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {

    .login-container {
        margin-top: 5vh;
        margin-bottom: 10vh;
        margin-left: -8vw;
        margin-right: -8vw;
        max-width: none;
        border: none;
        box-shadow: none;
        flex: 1;
    }

    .login-container .input-container {
        display: flex;
        flex-direction: column;
        max-width: none;
    }

    .login-container .logo-container .pleggit-logo svg {
        max-height: 8vw;
        margin-right: 2vw;
        margin-top: 3vh;
    }
    
    .login-container .logo-container .pleggit-title svg {
        max-height: 8vw;
        margin-top: 3vh;
    }

}