.np-expander {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 12px;
    background-color: lightgray;
    border-radius: 20px;
    padding: 2px 4px;
    cursor: pointer;
}
.np-expander:hover {
    background-color: var(--color-accent);
}
.np-expander svg {
    width: 24px;
    height: auto;
    color: grey;
}
.np-expander:hover svg {
    color: white;
}