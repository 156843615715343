
.payment-form .instructions {
    margin-top: var(--default-spacing);
}

.payment-form .row {
    display: flex;
}
.payment-form .instructions .text {
    margin: var(--default-spacing) 0 var(--default-card-spacing) 0;
    padding-top: 6px;
    border-top: 1px solid lightgray;
}
.payment-form .np-labeled-value {
    margin-top: 6px;
    flex: 1;
}
.payment-form .disclaimer {
    margin-top: var(--default-spacing);
    font-size: var(--font-small);
}