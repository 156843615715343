.screen {
    --font-main-text: 1.2vw;
    --font-main-text-small: 0.7vw;
    --font-menu-item: 0.9vw;
    --font-very-large: 1.9vw;
    --font-job-title: 1.2vw;
    --font-box-text: 1vw;
    --font-section-title: 2.9vw;
    --font-mobile-side-menu: 3.3vw;
    --section-padding-horizontal: 5vw;
    --section-padding-vertical: 2vw;
    
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
}

.screen .is-body {
    min-height: calc(100vh - var(--header-height) - 2px - var(--footer-height) - 1px);
}
/************ GENERIC ITEMS ****************/
.screen .section {
    padding: var(--section-padding-vertical) var(--section-padding-horizontal);
}
.screen .section .section-title {
    text-align: center;
}
.screen .section .section-title span {
    font-size: var(--font-section-title);
    margin: 0px 0.5vw;
    text-transform: uppercase;
}

.screen .section .section-title span.primary {
    color: var(--color-primary);
}

.screen .section .section-title span.accent-mk {
    color: var(--color-accent-mkt);
}

/***************** STANDARD SECTIONS ************/
.screen .section-wip {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.screen .section-wip .area-left {
    width: 40vw;
    font-size: var(--font-main-text);
}
.screen .section-wip .area-right {
    display: flex;
    flex: 1;
    justify-content: center;
}
.screen .section-wip .area-right svg {
    height: auto;
    width: 30vw;
}

@media only screen and (max-width: 500px) {

    .screen {
        --font-main-text: 4vw;
        --font-main-text-small: 2vw;
        --font-menu-item: 0.9vw;
        --font-very-large: 3vw;
        --font-normal: 3vw;
        --font-large: 4vw;
        --font-job-title: 4vw;
        --font-box-text: 1vw;
        --font-section-title: 7vw;
        --font-button: 4vw;
        --section-padding-horizontal: 5vw;
        --section-padding-vertical: 6vw;
        --header-height: 15vw;
        --header-logo-height: 6vw;
        --footer-height: 12vw;
    }
    /***************** STANDARD SECTIONS ************/
    .screen .section-wip {
        flex-direction: column;
    }
    .screen .section-wip .area-left {
        width: auto;
        flex: 1;
        font-size: var(--font-very-large);
    }
    .screen .section-wip .area-right {
        margin-top: 7vw;
    }
    .screen .section-wip .area-right svg {
        width: 50vw;
    }
}
