.np-button {
    /* padding: clamp(3px, 0.5vw, 6px) clamp(4px, 2vw, 32px); */
    padding: var(--default-button-padding);
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    outline: none;
    /* font-size: clamp(8px, 1vw, 18px); */
}

.np-button.nofill {
    font-weight: bold;
}

.np-button.marketing {
    background-color: var(--color-accent-mkt);
    color: white;
}

.np-button.nofill:hover {
    background-color: var(--color-accent);
    color: white;
}

.np-button.nofill.warning:hover {
    background-color: var(--color-complementary);
    color: white;
}

.np-button.large {
    padding: clamp(3px, 1vw, 12px) clamp(4px, 4vw, 64px);
}

.np-button.small {
    padding: 3px 12px;
    font-size: var(--font-small)
}

.np-button.with-shadow {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.np-button.with-shadow:hover {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}

.np-button.rises {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.np-button.rises:hover {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.np-button.outline {
    border: 2px solid var(--color-accent);
}

.np-button.outline.warning {
    border: 2px solid var(--color-complementary);
    color: var(--color-complementary);
}

.np-button.secondary {
    opacity: 0.4;
}

.np-button.light {
    color: white;
    border: 2px solid white;
}


/************** LABEL ******************/
.np-button .button-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
}

.np-button.large .button-title {
    font-size: clamp(8px, 1.2vw, 28px);
}

.np-button.with-shadow.pressed .button-title {
    opacity: 0.8;
}

/************+ RIPPLE *****************/
.np-button .ripple-container {
    overflow: hidden;
    display: flex;
    flex: 1;
}

.np-button div.ripple {
    position: absolute;
    /* The absolute position we mentioned earlier */
    border-radius: 50%;
    width: 50%;
    height: 100%;
    transform: scale(0);
    animation: ripple 300ms linear;
    background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .np-button {
        font-size: var(--font-button);
    }

    .np-button.large {
        padding: 12px 64px;
    }
    .np-button.large .button-title {
        font-size: var(--font-large);
    }
    
  }