
.iban-form .message {
    border-left: 3px solid var(--color-complementary);
    padding-left: var(--default-card-spacing);
}
.iban-form .input-container {
    margin-top: var(--default-spacing);
}
.iban-form .input-container.row {
    display: flex;
}