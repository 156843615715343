.tx-state-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-primary);
}
.tx-state-icon svg {
    width: 20px;
    height: auto;
    fill: currentColor;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .tx-state-icon svg {
        width: 20px;
    }
}