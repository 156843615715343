.dash-screen .card-title {
    font-size: var(--font-large)
}

.dash-screen .tx {
    display: flex;
    flex-direction: row;
}

.dash-screen .tx .left {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: var(--font-small)
}

.dash-screen .tx .right {
    font-size: var(--font-small);
    text-align: right;
}

.dash-screen .tx .title {
    font-size: var(--font-xlarge);
    margin-bottom: 6px;
}

.dash-screen .tx .price {
    font-size: var(--font-xlarge)
}

.dash-screen .tx .name-container {
    text-transform: capitalize;
}
.dash-screen .tx .np-labeled-value {
    margin-bottom: 6px;
}

.dash-side {
    margin-left: 12px;
}

.dash-side .actions-container .actions {
    margin-top: 12px;
}

/**********************************************
 * LOADING TX CARD
 *********************************************/
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.dash-screen .tx.loading {
    color: rgb(240, 240, 240);
}

.dash-screen .tx.loading .title {
    background: linear-gradient(-45deg, #eeeeee, #d8d8d8, #e1e1e1, #b4b4b4);
    background-size: 400% 400%;
    animation: gradient 2s ease infinite;
    max-width: 30%;
}

.dash-screen .tx.loading .loading {
    color: var(--color-text-dark);
    opacity: 0.6;
}