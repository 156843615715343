
.np-simple-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--color-text-dark);
    cursor: pointer;
    margin: 6px 0;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: 150ms;
}
.np-simple-button:hover {
    opacity: 0.5;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: 150ms;
}

.np-simple-button .button-image {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid currentColor;
    border-radius: 50%;
    margin-right: 12px;
}
.np-simple-button .button-image svg {
    fill: currentColor;
}
.np-simple-button .button-image.m svg {
    width: 24px;
    height: 24px;
}