.registration-screen {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0 8vw;
}

.registration-screen .welcome-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 8vw;
    flex: 1;
    max-width: 40vw;
    margin-top: 20vh;
}

.registration-screen .welcome-component .title {
    font-size: var(--font-section-title);
    color: var(--color-primary);
}

.registration-screen .welcome-component .text.sidebar {
    border-left: 4px solid var(--color-accent-mkt);
    padding-left: 2vw;
}

.registration-screen .welcome-component .text {
    font-size: var(--font-main-text);
}

.registration-screen .vspacing {
    margin: 12px 0;
}

.registration-screen .welcome-component .vspacing.x2 {
    margin: 24px 0;
}

.registration-screen .welcome-component img {
    height: 4vw;
}

.registration-screen .login-container {
    position: relative;
    max-width: 30vw;
}

.registration-screen .login-container .desc {
    text-align: center;
    margin: 0 0 24px 0;
    font-size: var(--font-large);
}

.registration-screen .error {
    font-size: var(--font-normal);
    color: var(--color-complementary);
    margin: 1vh 0;
}

.registration-screen .overlaid-loader-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    background: rgba(255, 255, 255, 0.8);
    z-index: 2;
    border-radius: 5px;
}
.registration-screen .overlaid-loader-container.col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--default-spacing);
    box-sizing: border-box;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .registration-screen {
        flex-direction: column;
    }

    .registration-screen .welcome-component {
        flex-direction: column;
        margin-right: 0;
        max-width: none;
        margin-top: 10vh;
    }

    .registration-screen .login-container {
        margin-top: 5vh;
        max-width: none;
        margin-left: -8vw;
        margin-right: -8vw;
        border-left: none;
        border-right: none;
        border-radius: 0;
        box-shadow: none;
    }

    .registration-screen .welcome-component img {
        height: 6vh;
    }

}