.customer-transaction {
    cursor: pointer;
}
.customer-transaction:hover {
    background-color: rgba(0,0,0,0.05);
}

.customer-tx-legend .legend-button {
    background-color: rgba(0,0,0,0.05);
    padding: 3px 12px;
    border-radius: 20px;
    font-size: var(--font-small);
}
.customer-tx-legend .legend-button:hover {
    color: white;
    background-color: var(--color-accent);
    cursor: pointer;
}

.customer-tx-legend-popup {
    padding: var(--default-spacing);
    max-width: 600px;
}
.customer-tx-legend-popup .content {
    display: flex;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #efefef;
}
.customer-tx-legend-popup .content .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    min-width: 32px;
    height: 32px;
    background-color: var(--color-primary);
    border-radius: 3px;
    margin-right: var(--default-spacing);
}
.customer-tx-legend-popup .content .icon.warn {
    background-color: var(--color-complementary);
}
.customer-tx-legend-popup .content .icon svg {
    width: 20px;
    height: auto;
    fill: white;
}
.customer-tx-legend-popup .content .text .title {
    font-size: var(--font-large);
    margin-bottom: 3px;
}
.customer-tx-legend-popup .content .text .desc {
    font-size: var(--font-small);
    opacity: 0.7;
}