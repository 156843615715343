.dashboard-screen .card-title {
    font-size: var(--font-large); 
}
.dashboard-screen .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 24px;
}
.dashboard-screen .error-container {
    text-align: center;
    color: var(--color-text-dark);
    font-size: var(--font-small);
    background: var(--color-complementary);
    padding: 12px 0;
    border-radius: 5px;
    margin: 12px 0;
}
.dashboard-screen .np-card {
    position: relative;
}
.dashboard-screen .loader-container {
    position: absolute;
    z-index: 3;
    display: flex;
    width: 100%;
    height: 100%;
    margin: calc(-1 * var(--default-spacing));
    border-radius: var(--default-card-border-radius);
    background-color: rgba(255, 255, 255,0.8);
}
.dashboard-screen .fees-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 13px;
    margin-bottom: 24px;
}
.dashboard-screen .fees-container .fees {
    margin-left: 12px;
}
.dashboard-screen .fees-container .fees-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-height: 48px;
    position: relative;
    margin-left: 24px;
}
.dashboard-screen .fees-container .fees-loading-container .logo-container {
    margin: 0;
    position: absolute;
    transform: scale(0.5);
}

.dashboard-screen .form-title {
    font-size: var(--font-very-large);
    text-align: center;
    margin-bottom: 24px;
}