.np-labeled-value {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.np-labeled-value.center {
    text-align: center;
}

.np-labeled-value .label {
    opacity: 0.5;
}

.np-labeled-value .value-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
}

.np-labeled-value .value-container .value {
    display: flex;
    /* flex: 1; */
}

.np-labeled-value .value-container .value.center {
    justify-content: center;
    flex: 1;
    align-items: center;
}

.np-labeled-value .value-container .value.copy-animation {
    transform: scale(0.7);
    opacity: 0.7;
}

.np-labeled-value .value-container .value.large {
    font-size: var(--font-xlarge);
}
.np-labeled-value .value-container .value.medlarge {
    font-size: var(--font-large);
}

.np-labeled-value .checkmark {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin-left: var(--default-card-spacing);
}

.np-labeled-value .checkmark svg {
    width: 16px;
    height: 16px;
    fill: currentColor;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .np-labeled-value .checkmark {
        width: 12px;
        height: 12px;
    }

    .np-labeled-value .checkmark svg {
        width: 12px;
        height: 12px;
        fill: currentColor;
    }

}