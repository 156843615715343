.customer-transaction .labeled-value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.customer-transaction .labeled-value .label {
    font-size: var(--font-xsmall);
    opacity: 0.5;
}

.customer-transaction .tag {
    background-color: var(--color-primary);
    border-radius: 20px;
    text-align: center;
    color: white;
    font-size: var(--font-small);
}

.customer-transaction .tag.acquisto {
    background-color: var(--color-accent-mkt);
}
