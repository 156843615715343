.np-radio-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 6px 0;
}

.np-radio-buttons .label {
    margin-bottom: 3px;
}

.np-radio-buttons .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin: 3px 0;
}
.np-radio-buttons .option:hover {
    color: var(--color-accent);
}

.np-radio-buttons .option .radio {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid rgba(0,0,0,0.7);
    margin-right: 6px;
}
.np-radio-buttons .option:hover .radio {
    border-color: var(--color-accent);
}
.np-radio-buttons .option .radio svg {
    width: 12px;
    height: 12px;
}
.np-radio-buttons .option:hover .radio svg {
    fill: var(--color-accent);
}