.profile-for-tx-card {
    display: flex;
    flex-direction: column;
}

.profile-for-tx-card .two-col {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start !important;
}
.profile-for-tx-card .two-col .left {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 6px;
}
.profile-for-tx-card .two-col .right {
    display: flex;
    flex-direction: column;
    flex-basis: 33%;
}
.profile-for-tx-card .card-title {
    text-align: center;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
}