/************************************************
 * FORM STEPS
 ***********************************************/
.customer-profile .form-steps {
    display: flex;
    align-items: center;
}

.customer-profile .form-step {
    display: flex;
    flex: 1;
    align-items: center;
}

.customer-profile .form-step .number {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightgrey;
    color: grey;
    width: 32px;
    height: 32px;
}

.customer-profile .form-step.completed .number {
    background-color: var(--color-accent);
    color: white;
    font-weight: bold;
}

.customer-profile .form-step.current .number {
    background-color: var(--color-accent);
    color: white;
    font-weight: bold;
}

.customer-profile .form-step .line {
    display: flex;
    flex: 1;
    border: 3px solid lightgrey;
    background-color: lightgrey;
    height: 6px;
    box-sizing: border-box;
}

.customer-profile .form-step.completed .line {
    border-color: var(--color-accent);
    background-color: var(--color-accent);
}

.customer-profile .form-step.last .line {
    width: 0;
    display: none;
}

.customer-profile .form-step svg {
    fill: white;
    width: 16px;
    height: auto;
}

/************************************************
 * FORM 
 ***********************************************/
.customer-profile .form .row {
    display: flex;
    align-items: flex-start;
}

.customer-profile .form .col {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 12px;
}

.customer-profile .form .buttons-container {
    display: flex;
    flex-direction: row;
}

.customer-profile .form .error {
    margin-top: 24px;
    background-color: var(--color-complementary);
    padding: 6px 12px;
    border-radius: 3px;
}

/************************************************
 * FORM FINISHED
 ***********************************************/
.customer-profile .form.finished .success {
    display: flex;
    align-items: center;
}

.customer-profile .form.finished .title {
    font-size: var(--font-large);
    color: var(--color-primary);
}

.customer-profile .form.finished .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-primary);
    border: 3px solid currentColor;
    margin-right: 12px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
}

.customer-profile .form.finished .icon svg {
    fill: currentColor;
    width: 24px;
    height: 24px;
}

.customer-profile .form.finished .text b {
    color: var(--color-primary);
}

/************************************************
 * CUSTOMER PROFILE DATa
 ***********************************************/
.customer-profile-data .row {
    display: flex;
    flex-direction: row;
}
.customer-profile-data .row.spaced {
    margin-top: 12px;
}
.customer-profile-data .row .np-labeled-value, .customer-profile-data .row div {
    flex: 1;
}
.customer-profile-data .col {
    display: flex;
    flex-direction: column;
}
.customer-profile-data .dexpander {
    display: flex;
    flex: 1;
    justify-content: center;
    background-color: rgba(0,0,0,0.05);
    margin-top: 24px;
    border-radius: 20px;
    cursor: pointer;
}
.customer-profile-data .dexpander:hover {
    background-color: var(--color-accent);
}
.customer-profile-data .dexpander svg {
    width: 24px;
    height: auto;
    fill: lightgray;
}
.customer-profile-data .dexpander:hover svg {
    fill: white;
}