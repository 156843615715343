.np-square-button {
    opacity: 1;
    display: flex;
    align-items: center;
    background-color: var(--color-accent);
    padding: 12px;
    cursor: pointer;
    border-radius: 3px;
    transition: box-shadow 0.2s ease-in-out, opacity 0.4s ease-in-out;
    color: white;
    box-sizing: border-box;
}
.np-square-button:hover {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.25);
}
.np-square-button .icon {
    margin-right: 12px; 
}
.np-square-button svg {
    width: 24px;
    height: 24px;
    fill: white;
}
.np-square-button.pressed {
    opacity: 0.7;
    transition: box-shadow 0.01s ease-in-out, opacity 0.2s ease-in-out;
    box-shadow: none;
}