.pinfo-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f3ff;
    height: 100vh;
}

.pinfo-screen .pinfo-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #e6e6f0;
    padding: 48px 72px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    background-color: white;
    max-width: 40vw;
}

.pinfo-screen .logo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 48px;
}

.pinfo-screen .logo-container .pleggit-logo svg {
    max-height: 1.3vw;
    width: auto
}

.pinfo-screen .logo-container .pleggit-title svg {
    fill: var(--color-primary);
    width: auto;
    max-height: 1.3vw;
}

.pinfo-screen .agency-logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: var(--default-spacing);
}

.pinfo-screen .agency-logo-container img {
    max-height: 6vw;
    width: auto
}

.pinfo-screen .info-container .tx-intro {
    text-align: center;
}

.pinfo-screen .info-container .license-plates {
    font-size: var(--font-xlarge);
    font-weight: bold;
    text-align: center;
    margin-top: 6px;
}

.pinfo-screen .info-container .pay-intro {
    margin-top: 48px;
}

.pinfo-screen .info-container .pay-intro p {
    margin: 3px 0;
}

.pinfo-screen .info-container .pay-data {
    margin-top: 24px;
}

.pinfo-screen .info-container .pay-data .np-labeled-value {
    margin: 12px 0;
}
.pinfo-screen .info-container .fees-info {
    display: flex;
    flex-direction: row;
    margin-top: 48px;
    border: 1px solid #e3e2e2;
    border-radius: 3px;
    padding: 12px;
    box-sizing: border-box;
    border-left: 3px solid #e3e2e2;
}
.pinfo-screen .info-container .fees-info .np-labeled-value {
    margin-right: 24px;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .pinfo-screen {
        background-color: white;
        height: 100vh;
        font-size: var(--font-large);
        align-items: flex-start;
    }

    .pinfo-screen .pinfo-container {
        padding: 48px 24px;
        border-radius: 0;
        border: none;
        box-shadow: none;
        background-color: white;
        width: 100vw;
        max-width: none;
    }

    .pinfo-screen .agency-logo-container img {
        max-width: 80vw;
        max-height: none;
    }

    .pinfo-screen .logo-container {
        margin-top: 12px;
    }

    .pinfo-screen .logo-container .pleggit-logo svg {
        max-height: 5vw;
    }

    .pinfo-screen .logo-container .pleggit-title svg {
        max-height: 5vw;
        margin-top: 2px;
        margin-left: 6px;
    }

    .pinfo-screen .info-container .license-plates {
        font-size: var(--font-enormous);
    }
    .pinfo-screen .info-container .fees-info {
        flex-direction: column;
        padding: 6px 12px;
        margin-top: 24px;
    }
    .pinfo-screen .info-container .fees-info .np-labeled-value {
        margin: 6px !important;
    }

}