.ucs-screen .card-title {
    font-size: var(--font-large)
}
.ucs-screen .unique-link {
    margin-top: 24px;
}
.ucs-screen .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 48px;
}