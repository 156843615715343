
.validation-error-container {
    display: flex;
    align-items: center;
    border-left: 3px solid var(--color-complementary);
    background-color: #FFE4BF;
    padding: 3px 6px;
}
.validation-error-container .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: var(--default-card-spacing);
}
.validation-error-container .icon svg {
    fill: var(--color-complementary);
    width: 32px;
    height: auto;
}