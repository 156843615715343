.np-list {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.np-list .list-header {
    display: flex;
    flex-direction: row;
    opacity: 0.6;
    margin-bottom: 9px;
    padding: 0 6px;
    align-items: flex-end;
} 
.np-list .list-header .header-field {
    display: flex;
    text-align: left;
} 
.np-list .list-header .header-field.cf {
    flex-basis: 30%;
} 
.np-list .list-header .header-field.date {
    flex-basis: 20%;
} 
.np-list .list-header .header-field.name {
    flex: 1;
} 
.np-list .list-header .header-field.validated {
    text-align: center;
    flex-basis: 10%;
} 

/***************++ FILTERS **********************/
.np-list .list-filters {
    display: flex;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
}
.np-list .list-filters .icon-filter-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.np-list .list-filters .icon-filter-container .filter-icon-container {
    display: flex;
    margin: 0 3px;
    cursor: pointer;
}
.np-list .list-filters .icon-filter-container .filter-icon-container.unselected {
    color: #e1e1e1; 
}
.np-list .list-filters .icon-filter-container .filter-icon-container svg {
    fill: currentColor !important;
}

/***************++ BODY *************************/

.np-list .list-body {
    display: flex;
    flex-direction: column;
} 
.np-list .list-body .line {
    display: flex;
    flex-direction: row;
    margin: 9px 0;
    padding: 0 6px;
    text-align: left;
    cursor: pointer;
}
.np-list .list-body .list-item-container:hover {
    background-color: #f3f3f3;
}
.np-list .list-body .line .value.icon {
    display: flex;
    justify-content: center;
    align-items: center;
} 
.np-list .list-body .line .value.icon span {font-size: 0;}
.np-list .list-body .line .value.icon svg {
    fill: currentColor;
    width: 16px;
    height: 16px;
}
.np-list .list-body .line .value.flag {
    display: flex;
    justify-content: center;
    align-items: center;
} 
.np-list .list-body .line .value.flag svg {
    width: 16px;
    height: 16px;
    fill: currentColor;
}

.np-list .separator {
    display: flex;
    border-top: 1px solid #e1e1e1;
}