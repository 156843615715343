.np-notification-card {
    display: flex;
    align-items: center;
}

.np-notification-card .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    border-radius: 50%;
    color: white;
    margin-right: var(--default-spacing);
    width: 32px;
    height: 32px;
}

.np-notification-card .icon svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
}

.np-notification-card-container {
    animation: fadeOut 1s forwards 3s;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        height: 100%;
    }

    90% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        visibility: hidden;
        height: 0;
    }
}